import prod_module from "./Binding.mjs";

const decoder = new TextDecoder();

export async function inference(serialized_input)
{
    const myModule = await prod_module();
    console.log("[wasm_io.js::inference()] module initialized ", myModule);

    console.log("[wasm_io.js::inference()] Writing string ", serialized_input);
    var inputLength = serialized_input.length;
    const input_buffer_ptr = myModule.asm.malloc(inputLength + 1);
    await myModule.stringToUTF8(serialized_input, input_buffer_ptr, inputLength*4 + 1)
    console.log("[wasm_io.js::inference()] Wrote string to WASM Heap at ", input_buffer_ptr);
    
    // runAI(string memory addr, string length, inference depth)
    var ret_val = await myModule.runAI(input_buffer_ptr, inputLength, 2);
    console.log("[wasm_io.js::inference()] AI inference received ", ret_val);
    
    var out_buffer_ptr = ret_val[0];
    var out_buffer_size = ret_val[1];
    var buffer = new Uint8Array(myModule.HEAPU32.buffer, out_buffer_ptr, out_buffer_size);
    var out_str = decoder.decode(buffer);
    var return_info = JSON.parse(out_str);
    console.log('[wasm_io.js::inference()] decoded to json', return_info);


    await myModule.asm.free(input_buffer_ptr);
    console.log('[wasm_io.js::inference()] input buffer freed', input_buffer_ptr);
    await myModule.asm.free(out_buffer_ptr);
    console.log('[wasm_io.js::inference()] output buffer freed', out_buffer_ptr);

    return return_info;
}
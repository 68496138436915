import React, { useEffect, useRef }  from 'react';

// Contains functions for drawing gameboard
// Lies as a background

export function DrawBoard(w, h){

  const canvasRef = useRef(null);

  /*
  useEffect(() => {
    
    console.log(size)
    // set gameboard parameters

    // get parent div width and height
    var = document.getElementById('boardContainer')
    window.addEventListener('resize', resizer);
    // need to re-render here?
    return () => window.removeEventListener('resize', resizer);
   
  },[canvasRef, size]);
  */
  
  useEffect(() => {
    canvasRef.current.width = w
    canvasRef.current.height = h

    //canvasObj.width = parent_div.clientWidth;
    //canvasObj.height = parent_div.clientHeight;
    
    combinedDrawing(canvasRef)
    
  }, [])
  
  return(
    <canvas ref={canvasRef} >
    </canvas>
  );
};

/////////////////////////////////////////////////////////
/*
 Geometrical Drawing Funtions from here
*/
function combinedDrawing(canvasRef){
  var ctx = canvasRef.current.getContext('2d');

  var board_w = canvasRef.current.width;
  var board_h = canvasRef.current.height;
  const board_x1 = 0;
  const board_y1 = 0;

  const margin = board_w * 0.05

  // #D6CCC1 <- pantone 13-0003
  // #66686C <- pantone dark-grey
  // #CBAC6F <- board color
  // #D6A37A <- lighter new board color
  // #886851 <- new board color
  ctx.beginPath();
  ctx.fillStyle = "#D6A37A";
  ctx.roundRect(board_x1, board_y1, board_w, board_h, 20);
  ctx.fill();

  // define line geometries
  const board_inner_x1 = board_x1 + margin
  const board_inner_y1 = board_y1 + margin
  const board_inner_x2 = board_x1 + board_w - margin
  const board_inner_y2 = board_y1 + board_h - margin

  const dx_line = (board_inner_x2 - board_inner_x1) / 8
  const dy_line = (board_inner_y2 - board_inner_y1) / 9
  const dx_small = dx_line / 10

  // create grid
  var grid = []
  var curr_x;
  var curr_y;
  var curr_coord;
  var curr_row = [];

  for (let j = 0; j < 10; j++) {
    curr_y = board_inner_y1 + dy_line * j;
    for (let i = 0; i < 9; i++) {
      curr_x = board_inner_x1 + dx_line * i;
      curr_coord = [curr_x, curr_y]
      curr_row.push(curr_coord)
    }
    grid.push(curr_row)
    curr_row = [];
  }

  // draw horizontal lines
  for (let j = 0; j < 10; j++) {
    curr_y = board_inner_y1 + dy_line * j;      
    drawLine(ctx, { x1: board_inner_x1, y1: curr_y, x2: board_inner_x2, y2: curr_y});

    for (let i = 0; i < 9; i++) {
      curr_x = board_inner_x1 + dx_line * i;      
      drawLine(ctx, { x1: curr_x, y1: board_inner_y1, x2: curr_x, y2: board_inner_y2});
      if (i ===4 && j ===1){
        drawDiag (ctx, curr_x, curr_y, dx_line, dy_line);
      } else if (i ===4 && j ===8){
        drawDiag (ctx, curr_x, curr_y, dx_line, dy_line);
      } else if (j ===2 && (i===1 || i===7)){
        drawDiag (ctx, curr_x, curr_y, dx_small, dx_small);
      } else if (j ===7 && (i===1 || i===7)){
        drawDiag (ctx, curr_x, curr_y, dx_small, dx_small);
      } else if (j ===3 && (i===0 || i===2 || i===4 || i===6 || i===8)){
        drawDiag (ctx, curr_x, curr_y, dx_small, dx_small);
      } else if (j ===6 && (i===0 || i===2 || i===4 || i===6 || i===8)){
        drawDiag (ctx, curr_x, curr_y, dx_small, dx_small);
      }
    }
  }

  /*
  // points for testing spots
  for (let j = 0; j < 10; j++) {
    for (let i = 0; i < 9; i++) {
      ctx.fillStyle = "#00FF00";
      ctx.fillRect(grid[j][i][0],grid[j][i][1],1,1); // fill in the pixel at (10,10)
      ctx.fill();
    }
  }
  */
  // Show test image
  ctx.closePath();
}

function drawDiag (ctx, x, y, dx, dy){
  drawLine(ctx, { x1: x, y1: y, x2: x - dx, y2: y - dy});
  drawLine(ctx, { x1: x, y1: y, x2: x + dx, y2: y - dy});
  drawLine(ctx, { x1: x, y1: y, x2: x - dx, y2: y + dy});
  drawLine(ctx, { x1: x, y1: y, x2: x + dx, y2: y + dy});
}

// #231F20 <- line color
function drawLine (ctx, info){
  const { x1, y1, x2, y2 } = info;
 
  ctx.moveTo(x1, y1);
  ctx.lineTo(x2, y2);
  ctx.strokeStyle = '#231F20';
  ctx.lineWidth = 1;
  ctx.stroke();
}

export function getGrid(w, h){

  var grid = []
  const board_w = w;
  const board_h = h;

  const board_x1 = 0;
  const board_y1 = 0;

  const margin = board_w * 0.05


  const board_inner_x1 = board_x1 + margin
  const board_inner_y1 = board_y1 + margin
  const board_inner_x2 = board_x1 + board_w - margin
  const board_inner_y2 = board_y1 + board_h - margin

  const dx_line = (board_inner_x2 - board_inner_x1) / 8
  const dy_line = (board_inner_y2 - board_inner_y1) / 9


  var curr_x;
  var curr_y;
  var curr_coord;
  var curr_row = [];
  for (let j = 0; j < 10; j++) {
    curr_y = board_inner_y1 + dy_line * j;
    for (let i = 0; i < 9; i++) {
      curr_x = board_inner_x1 + dx_line * i;
      curr_coord = [curr_x, curr_y]
      curr_row.push(curr_coord)
    }
    grid.push(curr_row)
    curr_row = [];
  }
  return(grid)

};


const player_types = ['HAN', 'CHO']
const piece_types = ['Jang', 'Cha', 'Po', 'Ma', 'Sang', 'Sa', 'Zol']
const empty_spot = '.'
const positions_palace = [[3, 0], [4, 0], [5, 0], [3, 1], [4, 1], [5, 1], [3, 2], [4, 2], [5, 2],
                    [3, 7], [4, 7], [5, 7], [3, 8], [4, 8], [5, 8], [3, 9], [4, 9], [5, 9]]

export function Legal_moves(position, board){
    const x = position[0];
    const y = position[1];
    const piece = board[y][x];
    var pseudo_legal_moves = []; // Moves that are allowed by definition of piece
    
    if (piece === 'J' || piece === 'j'){
        pseudo_legal_moves = Jang_moves(position, board)
    } else if (piece === 'C' || piece ==='c'){
        pseudo_legal_moves = Cha_moves(position, board)
    } else if (piece === 'P' || piece === 'p'){
        pseudo_legal_moves = Po_moves(position, board)
    } else if (piece === 'M' || piece === 'm'){
        pseudo_legal_moves = Ma_moves(position, board)
    } else if (piece === 'S' || piece === 's'){
        pseudo_legal_moves = Sang_moves(position, board)
    } else if (piece === 'Z' || piece === 'z'){
        pseudo_legal_moves = Zol_moves(position, board)
    } else if (piece === 'T' || piece === 't'){
        pseudo_legal_moves = Sa_moves(position, board)
    } else{
        pseudo_legal_moves = []
    }

    // later check if each moves get myself checked. (Illegal moves by rules of game)

    return (pseudo_legal_moves)
}

function Jang_moves(position, board){
    if (!(includes(positions_palace, position))){
        const errorMessage =  { message : "Jang location outside palace" };
        throw (errorMessage)
    }
    return palace_moves(position, board)
}

function Sa_moves(position, board){
    if (!(includes(positions_palace, position))){
        const errorMessage =  { message : "Sa location outside palace" };
        throw (errorMessage)
    }
    return palace_moves(position, board)
}

function Cha_moves(position, board){
    var possible_moves = []
    const x = position[0]
    const y = position[1]
    const curr_piece = board[y][x]
    const is_piece_upper = (curr_piece === 'C')

    // In palace
    possible_moves = palace_moves(position, board)

    // N direction
    var new_x = x
    var new_y = y
    while (new_y > 0){
        new_y -= 1
        if (board[new_y][new_x] === empty_spot){
            possible_moves.push([new_x, new_y])
        }
        else if ((board[new_y][new_x] === board[new_y][new_x].toUpperCase()) !== is_piece_upper){
            possible_moves.push([new_x, new_y])
            break
        }
        else {
            break
        }
    }
    // E direction
    new_x = x
    new_y = y
    while (new_x < 8){
        new_x += 1
        if (board[new_y][new_x] === empty_spot)
            possible_moves.push([new_x, new_y])
        else if ((board[new_y][new_x] === board[new_y][new_x].toUpperCase()) !== is_piece_upper){
            possible_moves.push([new_x, new_y])
            break
        }
        else{
            break
        }
    }
    // S direction
    new_x = x
    new_y = y
    while (new_y < 9){
        new_y += 1
        if (board[new_y][new_x] === empty_spot)
            possible_moves.push([new_x, new_y])
        else if ((board[new_y][new_x] === board[new_y][new_x].toUpperCase()) !== is_piece_upper){
            possible_moves.push([new_x, new_y])
            break
        }
        else {
            break
        }
    }
    // W direction
    new_x = x
    new_y = y
    while (new_x > 0){
        new_x -= 1
        if (board[new_y][new_x] === empty_spot)
            possible_moves.push([new_x, new_y])
        else if ((board[new_y][new_x] === board[new_y][new_x].toUpperCase()) !== is_piece_upper){
            possible_moves.push([new_x, new_y])
            break
        }
        else {
            break
        }
    }
    return possible_moves;
}

    
function Po_moves(position, board){
    var possible_moves = []
    const x = position[0]
    const y = position[1]
    const is_piece_upper = (board[y][x] === 'P')
    var anchor;
    var anchor_x;
    var anchor_y;
    var land_y;
    var land_x;

    // Search N
    if (y > 1){
        anchor = false
        anchor_y = y - 1
        while (anchor_y > 0){ // look for a piece to jump over
            if (board[anchor_y][x] === 'p' || board[anchor_y][x] === 'P'){
                break
            }
            else if (board[anchor_y][x] !== empty_spot){
                anchor = true
                break
            }
            else {anchor_y -= 1}   
        }
        if (anchor){
            land_y = anchor_y - 1
            land_x = x
            while (land_y >= 0){
                if (board[land_y][land_x] === empty_spot){
                    possible_moves.push([land_x, land_y])
                }
                else if (board[land_y][land_x] === 'p' || board[land_y][land_x] ==='P'){
                    break
                }
                else if ((board[land_y][land_x] === board[land_y][land_x].toUpperCase()) !== is_piece_upper){
                    possible_moves.push([land_x, land_y])
                    break
                }
                else {break}
                land_y -= 1
            }
        }
    }
    // Search E
    if (x < 7){
        anchor = false
        anchor_x = x + 1
        while (anchor_x < 8){
            if (board[y][anchor_x] === 'p' || board[y][anchor_x] === 'P'){
                break
            }
            else if (board[y][anchor_x] !== empty_spot){
                anchor = true
                break
            }
            else {anchor_x += 1}
        }
        if (anchor){
            land_x = anchor_x + 1
            land_y = y
            while (land_x <= 8){
                if (board[land_y][land_x] === empty_spot){
                    possible_moves.push([land_x, land_y])
                }
                else if (board[land_y][land_x] === 'p' || board[land_y][land_x] ==='P'){
                    break
                }
                else if ((board[land_y][land_x] === board[land_y][land_x].toUpperCase()) !== is_piece_upper){
                    possible_moves.push([land_x, land_y])
                    break
                }
                else{
                    break
                }
                land_x += 1
            }
        }
    }
    // Search S
    if (y < 8){
        anchor = false
        anchor_y = y + 1
        while (anchor_y < 9){
            if (board[anchor_y][x] === 'p' || board[anchor_y][x] === 'P'){
                break
            }
            else if (board[anchor_y][x] !== empty_spot){
                anchor = true
                break
            }
            else{
                anchor_y += 1
            }
        }
        if (anchor){
            land_y = anchor_y + 1
            land_x = x
            while (land_y <= 9){
                if (board[land_y][land_x] === empty_spot){
                    possible_moves.push([land_x, land_y])
                }
                else if (board[land_y][land_x] === 'p' || board[land_y][land_x] ==='P'){
                    break
                }
                else if ((board[land_y][land_x] === board[land_y][land_x].toUpperCase()) !== is_piece_upper){
                    possible_moves.push([land_x, land_y])
                    break
                }
                else{
                    break
                }
                land_y += 1
            }
        }
    }

    // Search W
    if (x > 1){
        anchor = false
        anchor_x = x - 1
        while (anchor_x > 0){
            if (board[y][anchor_x] === 'p' || board[y][anchor_x] === 'P'){
                break
            }
            else if (board[y][anchor_x] !== empty_spot){
                anchor = true
                break
            }
            else{anchor_x -= 1}
        }
        if (anchor){
            land_x = anchor_x - 1
            land_y = y
            while (land_x >= 0){
                if (board[land_y][land_x] === empty_spot){
                    possible_moves.push([land_x, land_y])
                }
                else if (board[land_y][land_x] === 'p' || board[land_y][land_x] ==='P'){
                    break
                }
                else if ((board[land_y][land_x] === board[land_y][land_x].toUpperCase()) !== is_piece_upper){
                    possible_moves.push([land_x, land_y])
                    break
                }
                else{break}
                land_x -= 1
            }
        }
    }
    return possible_moves;
}

function Ma_moves(position, board){
    var possible_moves = []
    const x = position[0]
    const y = position[1]
    const is_piece_upper = (board[y][x] === 'M')
    var temp_pos
    var temp_pos2

    // N direction
    if (y > 1){
        temp_pos = move_N(position)
        if (temp_pos && board[temp_pos[1]][temp_pos[0]] === empty_spot){
            // NE direction
            temp_pos2 = move_NE(temp_pos)
            if (temp_pos2 && board[temp_pos2[1]][temp_pos2[0]] === empty_spot)
                possible_moves.push(temp_pos2)
            else if (temp_pos2 && (board[temp_pos2[1]][temp_pos2[0]] === board[temp_pos2[1]][temp_pos2[0]].toUpperCase()) !== is_piece_upper){
                possible_moves.push(temp_pos2)
            }
            // NW direction
            temp_pos2 = move_NW(temp_pos)
            if (temp_pos2 && board[temp_pos2[1]][temp_pos2[0]] === empty_spot)
                possible_moves.push(temp_pos2)
            else if (temp_pos2 && (board[temp_pos2[1]][temp_pos2[0]] === board[temp_pos2[1]][temp_pos2[0]].toUpperCase()) !== is_piece_upper){
                possible_moves.push(temp_pos2)
            }
        }
    }
    // E direction
    if (x < 7){
        temp_pos = move_E(position)
        if (temp_pos && board[temp_pos[1]][temp_pos[0]] === empty_spot){
            // NE direction
            temp_pos2 = move_NE(temp_pos)
            if (temp_pos2 && board[temp_pos2[1]][temp_pos2[0]] === empty_spot)
                possible_moves.push(temp_pos2)
            else if (temp_pos2 && (board[temp_pos2[1]][temp_pos2[0]] === board[temp_pos2[1]][temp_pos2[0]].toUpperCase()) !== is_piece_upper)
                possible_moves.push(temp_pos2)

            // SE direction
            temp_pos2 = move_SE(temp_pos)
            if (temp_pos2 && board[temp_pos2[1]][temp_pos2[0]] === empty_spot)
                possible_moves.push(temp_pos2)
            else if (temp_pos2 && (board[temp_pos2[1]][temp_pos2[0]] === board[temp_pos2[1]][temp_pos2[0]].toUpperCase()) !== is_piece_upper)
                possible_moves.push(temp_pos2)
        }
    }
    // S direction
    if (y < 8){
        temp_pos = move_S(position)
        if (temp_pos && board[temp_pos[1]][temp_pos[0]] === empty_spot){
            // SE direction
            temp_pos2 = move_SE(temp_pos)
            if (temp_pos2 && board[temp_pos2[1]][temp_pos2[0]] === empty_spot)
                possible_moves.push(temp_pos2)
            else if (temp_pos2 && (board[temp_pos2[1]][temp_pos2[0]] === board[temp_pos2[1]][temp_pos2[0]].toUpperCase()) !== is_piece_upper)
                possible_moves.push(temp_pos2)

            // SW direction
            temp_pos2 = move_SW(temp_pos)
            if (temp_pos2 && board[temp_pos2[1]][temp_pos2[0]] === empty_spot)
                possible_moves.push(temp_pos2)
            else if( temp_pos2 && (board[temp_pos2[1]][temp_pos2[0]] === board[temp_pos2[1]][temp_pos2[0]].toUpperCase()) !== is_piece_upper)
                possible_moves.push(temp_pos2)
        }
    }
    // W direction
    if (x > 1){
        temp_pos = move_W(position)
        if (temp_pos && board[temp_pos[1]][temp_pos[0]] === empty_spot){

            // NW direction
            temp_pos2 = move_NW(temp_pos)
            if (temp_pos2 && board[temp_pos2[1]][temp_pos2[0]] === empty_spot)
                possible_moves.push(temp_pos2)
            else if( temp_pos2 && (board[temp_pos2[1]][temp_pos2[0]] === board[temp_pos2[1]][temp_pos2[0]].toUpperCase()) !== is_piece_upper)
                possible_moves.push(temp_pos2)

            // SW direction
            temp_pos2 = move_SW(temp_pos)
            if (temp_pos2 && board[temp_pos2[1]][temp_pos2[0]] === empty_spot)
                possible_moves.push(temp_pos2)
            else if( temp_pos2 && (board[temp_pos2[1]][temp_pos2[0]] === board[temp_pos2[1]][temp_pos2[0]].toUpperCase()) !==is_piece_upper)
                possible_moves.push(temp_pos2)
        }
    }
    return possible_moves;
}

function Sang_moves(position, board){
    var possible_moves = []
    const x = position[0]
    const y = position[1]
    const is_piece_upper = (board[y][x] === 'S')
    var temp_pos
    var temp_pos2

    // N direct(ion
    if (y > 2){
        temp_pos = move_N(position)
        if (temp_pos && board[temp_pos[1]][temp_pos[0]] === empty_spot){
            // NE direction
            temp_pos2 = move_NE(temp_pos)
            if (temp_pos2 && board[temp_pos2[1]][temp_pos2[0]] === empty_spot){
                temp_pos2 = move_NE(temp_pos2)
                if (temp_pos2 && board[temp_pos2[1]][temp_pos2[0]] === empty_spot){
                    possible_moves.push(temp_pos2)
                }
                else if( temp_pos2 && (board[temp_pos2[1]][temp_pos2[0]] === board[temp_pos2[1]][temp_pos2[0]].toUpperCase()) !== is_piece_upper){
                    possible_moves.push(temp_pos2)
                }
            }

            // NW direction
            temp_pos2 = move_NW(temp_pos)
            if (temp_pos2 && board[temp_pos2[1]][temp_pos2[0]] === empty_spot){
                temp_pos2 = move_NW(temp_pos2)
                if (temp_pos2 && board[temp_pos2[1]][temp_pos2[0]] === empty_spot){
                    possible_moves.push(temp_pos2)
                }
                else if( temp_pos2 && (board[temp_pos2[1]][temp_pos2[0]] === board[temp_pos2[1]][temp_pos2[0]].toUpperCase()) !== is_piece_upper){
                    possible_moves.push(temp_pos2)
                }
            }
        }
    }
    // E direct(ion
    if (x < 6){
        temp_pos = move_E(position)
        if (temp_pos && board[temp_pos[1]][temp_pos[0]] === empty_spot){
            // NE direction
            temp_pos2 = move_NE(temp_pos)
            if (temp_pos2 && board[temp_pos2[1]][temp_pos2[0]] === empty_spot){
                temp_pos2 = move_NE(temp_pos2)
                if (temp_pos2 && board[temp_pos2[1]][temp_pos2[0]] === empty_spot){
                    possible_moves.push(temp_pos2)
                }
                else if( temp_pos2 && (board[temp_pos2[1]][temp_pos2[0]] === board[temp_pos2[1]][temp_pos2[0]].toUpperCase()) !== is_piece_upper){
                    possible_moves.push(temp_pos2)
                }
            }
            // SE direction
            temp_pos2 = move_SE(temp_pos)
            if (temp_pos2 && board[temp_pos2[1]][temp_pos2[0]] === empty_spot){
                temp_pos2 = move_SE(temp_pos2)
                if (temp_pos2 && board[temp_pos2[1]][temp_pos2[0]] === empty_spot){
                    possible_moves.push(temp_pos2)
                }
                else if( temp_pos2 && (board[temp_pos2[1]][temp_pos2[0]] === board[temp_pos2[1]][temp_pos2[0]].toUpperCase()) !== is_piece_upper){
                    possible_moves.push(temp_pos2)
                }
            }
        }
    }
    // S direction
    if (y < 7){
        temp_pos = move_S(position)
        if (temp_pos && board[temp_pos[1]][temp_pos[0]] === empty_spot){
            // SE direction
            temp_pos2 = move_SE(temp_pos)
            if (temp_pos2 && board[temp_pos2[1]][temp_pos2[0]] === empty_spot){
                temp_pos2 = move_SE(temp_pos2)
                if (temp_pos2 && board[temp_pos2[1]][temp_pos2[0]] === empty_spot){
                    possible_moves.push(temp_pos2)
                }
                else if( temp_pos2 && (board[temp_pos2[1]][temp_pos2[0]] === board[temp_pos2[1]][temp_pos2[0]].toUpperCase()) !== is_piece_upper){
                    possible_moves.push(temp_pos2)
                } 
            }

            // SW direction
            temp_pos2 = move_SW(temp_pos)
            if (temp_pos2 && board[temp_pos2[1]][temp_pos2[0]] === empty_spot){
                temp_pos2 = move_SW(temp_pos2)
                if (temp_pos2 && board[temp_pos2[1]][temp_pos2[0]] === empty_spot){
                    possible_moves.push(temp_pos2)
                }
                else if( temp_pos2 && (board[temp_pos2[1]][temp_pos2[0]] === board[temp_pos2[1]][temp_pos2[0]].toUpperCase()) !== is_piece_upper){
                    possible_moves.push(temp_pos2)
                } 
            }
        }
    }
    // W direction
    if (x > 2){
        temp_pos = move_W(position)
        if (temp_pos && board[temp_pos[1]][temp_pos[0]] === empty_spot){
            // NW direction
            temp_pos2 = move_NW(temp_pos)
            if (temp_pos2 && board[temp_pos2[1]][temp_pos2[0]] === empty_spot){
                temp_pos2 = move_NW(temp_pos2)
                if (temp_pos2 && board[temp_pos2[1]][temp_pos2[0]] === empty_spot){
                    possible_moves.push(temp_pos2)
                }
                else if( temp_pos2 && (board[temp_pos2[1]][temp_pos2[0]] === board[temp_pos2[1]][temp_pos2[0]].toUpperCase()) !== is_piece_upper){
                    possible_moves.push(temp_pos2)
                }
            }

            // SW direction
            temp_pos2 = move_SW(temp_pos)
            if (temp_pos2 && board[temp_pos2[1]][temp_pos2[0]] === empty_spot){
                temp_pos2 = move_SW(temp_pos2)
                if (temp_pos2 && board[temp_pos2[1]][temp_pos2[0]] === empty_spot){
                    possible_moves.push(temp_pos2)
                }
                else if( temp_pos2 && (board[temp_pos2[1]][temp_pos2[0]] === board[temp_pos2[1]][temp_pos2[0]].toUpperCase()) !== is_piece_upper){
                    possible_moves.push(temp_pos2)
                }
            }
        }
    }

    return possible_moves
}

function Zol_moves(position, board){
    const x = position[0]
    const y = position[1]
    const is_piece_upper = (board[y][x] === 'Z')
    var possible_moves = []
    var temp_pos

    // E direction
    temp_pos = move_E(position)
    if (temp_pos){
        if (pos_empty_or_enemy(board, temp_pos, is_piece_upper)){
            possible_moves.push(temp_pos)
        }
    }

    // W direction
    temp_pos = move_W(position)
    if (temp_pos){
        if (pos_empty_or_enemy(board, temp_pos, is_piece_upper)){
            possible_moves.push(temp_pos)
        }
    }

    // N, S, && diags. Depends on playing side
    // Cho. Move only N direction
    if (board[y][x] === 'z'){
        temp_pos = move_N(position)
        if (temp_pos){
            if (pos_empty_or_enemy(board, temp_pos, is_piece_upper)){
                possible_moves.push(temp_pos)
            }
        }
        // Diagonal Moves
        // At center. push all 2 diags
        if (x === 4 && y === 1){
            //NW
            temp_pos = move_NW(position)
            if (temp_pos){
                if (pos_empty_or_enemy(board, temp_pos, is_piece_upper)){
                    possible_moves.push(temp_pos)
                }
            }
            //NE
            temp_pos = move_NE(position)
            if (temp_pos){
                if (pos_empty_or_enemy(board, temp_pos, is_piece_upper)){
                    possible_moves.push(temp_pos)
                }
            }
        }
        // At bottom left
        else if( x===3 && y===2){
            // NE
            temp_pos = move_NE(position)
            if (temp_pos){
                if (pos_empty_or_enemy(board, temp_pos, is_piece_upper)){
                    possible_moves.push(temp_pos)
                }
            }
        }
        // At bottom right
        else if (x===5 && y===2){
            // NW
            temp_pos = move_NW(position)
            if (temp_pos){
                if (pos_empty_or_enemy(board, temp_pos, is_piece_upper)){
                    possible_moves.push(temp_pos)
                }
            }
        }
    }
    // Han. Move only S direction
    else if( board[y][x] === 'Z'){
        temp_pos = move_S(position)
        if (temp_pos){
            if (pos_empty_or_enemy(board, temp_pos, is_piece_upper)){
                possible_moves.push(temp_pos)
            }
        }
        // Diagonal Moves
        // At center.
        if (x===4 && y=== 8){
            //SW
            temp_pos = move_SW(position)
            if (temp_pos){
                if (pos_empty_or_enemy(board, temp_pos, is_piece_upper)){
                    possible_moves.push(temp_pos)
                }
            }
            //SE
            temp_pos = move_SE(position)
            if (temp_pos){
                if (pos_empty_or_enemy(board, temp_pos, is_piece_upper)){
                    possible_moves.push(temp_pos)
                }
            }
        }
        // At Top left
        else if( x=== 3 && y=== 7){
            // SE
            temp_pos = move_SE(position)
            if (temp_pos){
                if (pos_empty_or_enemy(board, temp_pos, is_piece_upper)){
                    possible_moves.push(temp_pos)
                }
            }
        }
        // At Top right
        else if( x===5 && y=== 7){
            // SW
            temp_pos = move_SW(position)
            if (temp_pos){
                if (pos_empty_or_enemy(board, temp_pos, is_piece_upper)){
                    possible_moves.push(temp_pos)
                }
            }
        }
    }
    return possible_moves
}

function palace_moves(position, board){ // for pieces in palace
    const x = position[0]
    const y = position[1]

    if (board[y][x] === empty_spot){
        const errorMessage =  { message : 'Cannot move [.] in function palace moves' };
        throw (errorMessage)
    }

    const is_piece_upper = (board[y][x] === board[y][x].toUpperCase())
    var possible_moves = []
    var temp_pos;

    // push Diag moves
    if (board[y][x] === 'j' || board[y][x] === 't' || board[y][x] === 'c' || board[y][x] === 'C'){
        // Jang at center of palace
        if (x === 4 && y === 8){
            temp_pos = [x - 1, y - 1]
            if (pos_empty_or_enemy(board, temp_pos, is_piece_upper)){
                possible_moves.push(temp_pos)
            }
            temp_pos = [x + 1, y - 1]
            if (pos_empty_or_enemy(board, temp_pos, is_piece_upper)){
                possible_moves.push(temp_pos)
            }
            temp_pos = [x + 1, y + 1]
            if (pos_empty_or_enemy(board, temp_pos, is_piece_upper)){
                possible_moves.push(temp_pos)
            }
            temp_pos = [x - 1, y + 1]
            if (pos_empty_or_enemy(board, temp_pos, is_piece_upper)){
                possible_moves.push(temp_pos)
            }
        }
        else if( x === 3){
            if (y === 7){
                temp_pos = [x + 1, y + 1]
                if (pos_empty_or_enemy(board, temp_pos, is_piece_upper)){
                    possible_moves.push(temp_pos)
                }
            }
            else if( y === 9){
                temp_pos = [x, y - 1]
                if (pos_empty_or_enemy(board, temp_pos, is_piece_upper)){
                    possible_moves.push(temp_pos)
                }
                temp_pos = [x + 1, y - 1]
                if (pos_empty_or_enemy(board, temp_pos, is_piece_upper)){
                    possible_moves.push(temp_pos)
                }
                temp_pos = [x + 1, y]
                if (pos_empty_or_enemy(board, temp_pos, is_piece_upper)){
                    possible_moves.push(temp_pos)
                }
            }
        }
        else if(x === 5){
            if (y === 7){
                temp_pos = [x - 1, y + 1]
                if (pos_empty_or_enemy(board, temp_pos, is_piece_upper)){
                    possible_moves.push(temp_pos)
                }
            }
            else if(y === 9){
                temp_pos = [x - 1, y - 1]
                if (pos_empty_or_enemy(board, temp_pos, is_piece_upper)){
                    possible_moves.push(temp_pos)
                }
            }
        }
    }

    if (board[y][x] === 'J' || board[y][x] === 'T' || board[y][x] === 'c' || board[y][x] === 'C'){
        // Jang at center of palace
        if (x === 4 && y === 1){
            temp_pos = [x - 1, y - 1]
            if (pos_empty_or_enemy(board, temp_pos, is_piece_upper)){
                possible_moves.push(temp_pos)
            }
            temp_pos = [x + 1, y - 1]
            if (pos_empty_or_enemy(board, temp_pos, is_piece_upper)){
                possible_moves.push(temp_pos)
            }
            temp_pos = [x + 1, y + 1]
            if (pos_empty_or_enemy(board, temp_pos, is_piece_upper)){
                possible_moves.push(temp_pos)
            }
            temp_pos = [x - 1, y + 1]
            if (pos_empty_or_enemy(board, temp_pos, is_piece_upper)){
                possible_moves.push(temp_pos)
            }
        }
        else if(x === 3){
            if (y === 0){
                temp_pos = [x + 1, y + 1]
                if (pos_empty_or_enemy(board, temp_pos, is_piece_upper)){
                    possible_moves.push(temp_pos)
                }
            }
            else if( y === 2){
                temp_pos = [x + 1, y - 1]
                if (pos_empty_or_enemy(board, temp_pos, is_piece_upper)){
                    possible_moves.push(temp_pos)
                }
            }
        }
        else if( x === 5){
            if (y === 0){
                temp_pos = [x - 1, y + 1]
                if (pos_empty_or_enemy(board, temp_pos, is_piece_upper)){
                    possible_moves.push(temp_pos)
                }
            }
            else if( y === 2){
                temp_pos = [x - 1, y - 1]
                if (pos_empty_or_enemy(board, temp_pos, is_piece_upper)){
                    possible_moves.push(temp_pos)
                }
            }
        }
    }

    if (board[y][x] === 'c' || board[y][x] === 'C'){
        return possible_moves;
    }


    //push all four sides
    // N direction
    if (y < 3 || y > 7){
        temp_pos = move_N(position) 
        if (temp_pos){
            if (pos_empty_or_enemy(board, temp_pos, is_piece_upper)){
                possible_moves.push(temp_pos)
            }
        }
    }

    // E direction
    if (2 < x && x < 5){
        temp_pos = move_E(position)
        if (temp_pos){
            if (pos_empty_or_enemy(board, temp_pos, is_piece_upper)){
                possible_moves.push(temp_pos)
            }
        }
    }

    // S direction
    if (y < 2 || y > 6){
        temp_pos = move_S(position)
        if (temp_pos){
            if (pos_empty_or_enemy(board, temp_pos, is_piece_upper)){
                possible_moves.push(temp_pos)
            }
        }
    }

    // W direction
    if (3 < x && x < 6){
        temp_pos = move_W(position)
        if (temp_pos){
            if (pos_empty_or_enemy(board, temp_pos, is_piece_upper)){
                possible_moves.push(temp_pos)
            }
        }
    }

    

    return possible_moves
}

function pos_empty_or_enemy(board, temp_pos, is_origin_piece_upper){
    const temp_piece = board[temp_pos[1]][temp_pos[0]]
    if (temp_piece === empty_spot){
        return true
    }
    else if ((temp_piece === temp_piece.toUpperCase()) !== is_origin_piece_upper){
        return true
    }
    else{return false}
}

function includes(array1, value){
    var curr_array;
    for (let i = 0, len = array1.length ; i < len; i++) {
        curr_array = array1[i]
        if (curr_array[0] === value[0] && curr_array[1] === value[1]){
            return true
        }
    }
    return false
}

// Simply gets coordinate of designated direction.
// Does not care if a piece exists in original/destination coordinate or not
function move_N(piece_pos){  // run on a single piece to exp&& node
    const x = piece_pos[0]
    const y = piece_pos[1]
    var x2
    var y2

    if (y > 0){
        x2 = x
        y2 = y - 1
        return [x2, y2]
    }
    else{
        return false
    }
}
        

function move_NE(piece_pos){
    const x = piece_pos[0]
    const y = piece_pos[1]
    var x2
    var y2

    if (x < 8 && y > 0){
        x2 = x + 1
        y2 = y - 1
        return [x2, y2]
    }
    else
        return false
}

function move_E(piece_pos){
    const x = piece_pos[0]
    const y = piece_pos[1]
    var x2
    var y2

    if (x < 8){
        x2 = x + 1
        y2 = y
        return [x2, y2]
    }
    else
        return false
}

function move_SE(piece_pos){
    const x = piece_pos[0]
    const y = piece_pos[1]
    var x2
    var y2

    if (x < 8 && y < 9){
        x2 = x + 1
        y2 = y + 1
        return [x2, y2]
    }
    else
        return false
}

function move_S(piece_pos){
    const x = piece_pos[0]
    const y = piece_pos[1]
    var x2
    var y2

    if (y < 9){
        x2 = x
        y2 = y + 1
        return [x2, y2]
    }
    else
        return false
}

function move_SW(piece_pos){
    const x = piece_pos[0]
    const y = piece_pos[1]
    var x2
    var y2

    if (x > 0 && y < 9){
        x2 = x - 1
        y2 = y + 1
        return [x2, y2]
    }
    else
        return false
}

function move_W(piece_pos){
    const x = piece_pos[0]
    const y = piece_pos[1]
    var x2
    var y2
    if (x > 0){
        x2 = x - 1
        y2 = y
        return [x2, y2]
    }
    else
        return false
}
function move_NW(piece_pos){
    const x = piece_pos[0]
    const y = piece_pos[1]
    var x2
    var y2
    if (x > 0 && y > 0 ){
        x2 = x - 1
        y2 = y - 1
        return [x2, y2]
    }
    else
        return false
}

export function pieceScore(piece){
    if (piece === 'j' || piece === 'J'){
        return 0
    } else if (piece ==='C' || piece === 'c'){
        return 13
    }else if (piece === 'P' || piece === 'p'){
        return 7
    }else if (piece === 'M' || piece === 'm'){
        return 5
    }else if (piece === 'S' || piece === 's'){
        return 3
    }else if (piece === 'Z' || piece === 'z'){
        return 2
    }else if (piece === 'T' || piece === 't'){
        return 3
    }
}
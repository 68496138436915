import '../App.css'

export const googleAd = () => {

    return(
        <div id='bannerContainer' className="Banner-PlaceHolder">
            <script async src="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-6595353856734318"
            crossorigin="anonymous"></script>
        </div>

    )
}
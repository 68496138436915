import React, { Component }  from 'react';

// Assumes that the piece is not empty piece (.) 
export class ClickablePiece extends Component{
    constructor(props){
        super(props)

        this.state = {
            showMoves: false
        }

        //console.log('[struct]', 'id:', this.props.point_info.id, 'type:', this.props.point_info.piece_type, 'pos:', this.props.point_info.pos) //'side:', this.state.side)

        this.moveOptions = this.moveOptions.bind(this);
    }
    render(){
        var x = this.props.point_info.x_pixel - (this.props.point_info.image_w / 2)
        var y = this.props.point_info.y_pixel - (this.props.point_info.image_h / 2)
        var h = this.props.point_info.image_h

        return(
            <React.Fragment>
                <img src = {this.props.point_info.image_path} style={{position: 'absolute', top: y, left: x, height: h, opacity: 1}} 
                onClick={this.OnButtonClick.bind(this)}/>
                {this.state.showMoves ? (
                    this.moveOptions()
                    ) : null
                }
            </React.Fragment>
        )
    }

    // if Board's closeAllOptions change to True, receive info and close showing move options
    componentDidUpdate(prevProps, prevState){
        //console.log('open_id', this.props.point_info.id, 'Parent prev', prevProps.open_id, 'Parent open_id', this.props.open_id)
        if (this.props.point_info.id !== this.props.open_id){
            if (this.state.showMoves){
                console.log('did this turn off?')
                this.setState({showMoves: false})
            }
        }
    }

    // if turn == side, show moves. Else do nothing.
    // Same thing if currently waiting for AI, don't show any moves
    OnButtonClick() {
        console.log('---------------------------------------')
        console.log('Piece id', this.props.point_info.id, 'Piece type', this.props.point_info.piece_type)
        console.log('showMoves before', this.state.showMoves)
        //console.log('Side before click', this.state.side, 'showMoves before', this.state.showMoves)
        if (this.props.gameover){
            console.log('game is over')
            return
        }
        if (!this.props.didGameStart){
            console.log('game has not started yet')
            return
        }

        // Check turn
        if (isUpperCase(this.props.point_info.piece_type) === this.props.turn){
            console.log('gameModeInfo.gameMode:', this.props.gameModeInfo.gameMode)
            // if currently waiting for AI, don't show any moves
            if ((this.props.gameModeInfo.gameMode === 1 && this.props.gameModeInfo.AI_type===this.props.turn)){
                console.log('AI mode detected')

                // Check if already opened and close if opened.
                // setState updates state late, so send opposite of current showMoves to Parent first
                if (this.state.showMoves){
                    console.log('optionsOpened 99 AI.')
                    this.close()
                } 
            } else{
                // Not AI's turn
                console.log('showMoves middle', this.state.showMoves)

                // If showMoves=false(closed), open. Else, close
                if (!this.state.showMoves){
                    this.open()
                } else{
                    this.close()
                }
            }
        } else{
            console.log('not this piece turn')
        }
        
        //console.log('Side after click', this.state.side, 'showMoves after', this.state.showMoves)
        console.log('showMoves after', this.state.showMoves)
        console.log('---------------------------------------')
    }

    open(){
        this.props.optionsOpened(this.props.point_info.id)
        this.setState({showMoves: true} , 
            () => console.log("[ClickablePiece::open] ", this.props.point_info.id, "opened: ", this.state.showMoves) )
    }

    close(){
        this.props.optionsOpened(99)
        this.setState({showMoves: false} , 
            () => console.log("[ClickablePiece::open] ", this.props.point_info.id, "closed: ", this.state.showMoves) )
    }

    // actually move. Trigger a change in state of Game(board)(Parent), and then moved position propagates back down to this piece again.
    // Why desined this way? To prevent "board" and "piece" getting different controls.
    // Maybe find a better way later
    movePiece(from_pos, to_pos){
        console.log('[ClickablePiece::movePiece] ', from_pos, 'to', to_pos)
        this.props.receivePieceMove(from_pos, to_pos)
        this.close()
    }

    moveOptions(){
        console.log('[ClickablePiece::moveOptions] at ', this.props.point_info.id)
        var h = this.props.point_info.image_h
        return(
            <React.Fragment>
            {this.props.point_info.move_info.map((move_infos, index) => {
                    return(
                        <img src={this.props.point_info.image_path} style={{position: 'absolute', top: move_infos.y_pixel, left: move_infos.x_pixel, 
                        height: h, opacity: 0.4, zIndex:1}} key={index.toString()} onClick={() => this.movePiece(this.props.point_info.pos, move_infos.move_pos)}/>
                        // zIndex is not a typo!!
                    )
                })
            }
            </React.Fragment>
        )
    }

}

const isUpperCase = (string) => /^[A-Z]*$/.test(string)
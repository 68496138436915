import React, { useEffect, useRef, useState,}  from 'react';
import './App.css';
import GameBoard, {DrawBoard} from './graphics/gameboard'
import {DrawPieces, StartBoard, DrawGrid, DrawGame} from './graphics/game'

import {GameLogic} from './model/janggi'
import {GameGraphicInfo} from './graphics/game'
import {googleAd} from './GoogleAd/googleAd'

function DrawBanner(){
  // return(
  //   <div id='bannerContainer' className="Banner-PlaceHolder">
  //   </div>
  // )
}

function App() {
  
  // create state for window size, and pass down size to DrawBoard and DrawGame
  var board_w = 700
  var board_h = 700
  var panel_w = 200
  var panel_h = board_h
  var container_w = board_w + panel_w
  var board_dimension = {w:board_w, h:board_h}
  
  const ratio = 0.8

  return (
    <div className="App" >
      {googleAd()}
      <div id='gameContainer' className="game_container" width ={container_w} height={board_h}>
        <div id='boardContainer' className="game_board_container" width={board_w} height={board_h}>
          {DrawBoard(board_w, board_h)}
          <GameLogic board_dimension={board_dimension} />
        </div>
      </div>
    </div>
  );
}

export default App;


/*
  const [testMsg, setTestMsg] = useState(''); // test receive network message
  const [sendMsg, setSendMsg] = useState(''); // test sendnetwork message
  useEffect(() => {
    
    var sendToAddress = 'http://127.0.0.1:5000/'

    axios
      .post(sendToAddress, {num:'HI'})
      .then(res => {
        console.log("res:", res.data)
        setTestMsg(
          res.data.num
        );
      })
      .catch(err => {
        console.error(err)
      })
  }, []
  )
  */
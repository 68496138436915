import Han_Cha from '../assets-new/Han_Cha.png'
import Han_Jang from '../assets-new/Han_Jang.png'
import Han_Ma from '../assets-new/Han_Ma.png'
import Han_Po from '../assets-new/Han_Po.png'
import Han_Sa from '../assets-new/Han_Sa.png'
import Han_Sang from '../assets-new/Han_Sang.png'
import Han_Zol from '../assets-new/Han_Zol.png'
import Cho_Cha from '../assets-new/Cho_Cha.png'
import Cho_Jang from '../assets-new/Cho_Jang.png'
import Cho_Ma from '../assets-new/Cho_Ma.png'
import Cho_Po from '../assets-new/Cho_Po.png'
import Cho_Sa from '../assets-new/Cho_Sa.png'
import Cho_Sang from '../assets-new/Cho_Sang.png'
import Cho_Zol from '../assets-new/Cho_Zol.png'

const GetPieceImagePath= (type) => {
    //const image_path = require("../assets/".concat(color, "_", type, ".png"))
    var image_path;
    if (type === 'J'){
        image_path = Han_Jang
    } else if (type === 'j'){
        image_path = Cho_Jang
    } else if (type === 'C'){
        image_path = Han_Cha
    } else if (type === 'c'){
        image_path = Cho_Cha
    } else if (type === 'M'){
        image_path = Han_Ma
    } else if (type === "m"){
        image_path = Cho_Ma
    } else if (type === "P"){
        image_path = Han_Po
    } else if (type === "p"){
        image_path = Cho_Po
    } else if (type === "S"){
        image_path = Han_Sang
    } else if (type === "s"){
        image_path = Cho_Sang
    } else if (type === "T"){
        image_path = Han_Sa
    } else if (type === "t"){
        image_path = Cho_Sa
    } else if (type === "Z"){
        image_path = Han_Zol
    } else if (type === "z"){
        image_path = Cho_Zol
    }
    
    return(image_path)
};
export default GetPieceImagePath

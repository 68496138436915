import init_board from '../input.json';

export const readBoard = () => {
    var board = []
    var curr_line = []
    var curr_str = ""
    for (let j = 0; j < 10; j++){
        curr_str = init_board["line_".concat(j.toString())];
        for (let i = 0; i < 9; i++) {
            curr_line.push(curr_str[i])
        }
        console.log('[Board_IO::readBoard()] curr_line', curr_line)
        board.push(curr_line)
        curr_line = []
        
    }
    console.log('[Board_IO::readBoard()] return board', board)
    return board
}

export const readReturnBoard = (ret_json) => {
    console.log("[Board_IO.js::readReturnBoard() entered ", ret_json)

    var board = []
    var curr_line = []
    var curr_str = ""
    
    for (let j = 0; j < 10; j++){
        curr_str = ret_json["line_".concat(j.toString())];
        for (let i = 0; i < 9; i++) {
            //console.log("[Board_IO.js::readReturnBoard() line 27 val: ", curr_str[i])
            curr_line.push(curr_str[i])
        }
        board.push(curr_line)
        curr_line = []
        
    }
    
    return board
}


export const boardToDict = (board, player_type) => {
    var board_dict = {}
    

    if (player_type === "CHO" || player_type === "Cho" || player_type === false){
        board_dict["agent_type"] = "HAN"
        board_dict["player_type"] = "CHO";
    }    
    else if (player_type === "HAN" || player_type === "Han" || player_type === true){
        board_dict["agent_type"] = "CHO"
        board_dict["player_type"] = "HAN"
    }
    else{
        throw 'player_type must be either HAN or CHO';
    }

    board_dict["line_0"] = board[0].join("")
    board_dict["line_1"] = board[1].join("")
    board_dict["line_2"] = board[2].join("")
    board_dict["line_3"] = board[3].join("")
    board_dict["line_4"] = board[4].join("")
    board_dict["line_5"] = board[5].join("")
    board_dict["line_6"] = board[6].join("")
    board_dict["line_7"] = board[7].join("")
    board_dict["line_8"] = board[8].join("")
    board_dict["line_9"] = board[9].join("")

    return board_dict
}